<template>
  <div class="login-con">
    <div class="login-header" v-show="!($store.state.isHeaderHidden)">
      <img :src="$logo || '/images/logo.png'" class="login-header-icon" height="28" alt="Logo" v-show="!($store.state.isLogoHidden)">
    </div>
    <!-- 浏览器提示 -->
    <div class="bg_img" style="background: rgba(225,225,225,0.4);">
      <!-- 星空 -->
      <Constellation></Constellation>
    </div>
    <div class="login bg-100">
      <LoginBox
        v-if="!isShowForgetModal"
        @showForgetModal="showForgetModal"
        @login="login" />
      <ForgetPassword
        v-else
        @hideForgetModal="hideForgetModal"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import Constellation from '@/components/login/Constellation'
import LoginBox from '../components/login/LoginBox.vue'
import ForgetPassword from '../components/login/ForgetPassword.vue'
import { showModal } from '@/components/invite/InviteModal'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { USERS } from '@/store/type'
import { client } from '../router/path'
import api from '@/services/api.js'
export default defineComponent({
  components: {
    Constellation,
    LoginBox,
    ForgetPassword
  },
  props: {

  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      isShowForgetModal: false
    })

    const showForgetModal = () => {
      state.isShowForgetModal = true
    }

    const hideForgetModal = () => {
      state.isShowForgetModal = false
    }

    const login = async ({ user, password, phone, smsCode }, isPhone) => {
      let data = {}
      let res
      if (isPhone) {
        data = {
          phone,
          code: smsCode
        }
        res = await api.loginByPhone(data)
      } else {
        data = {
          username: user,
          password: password
        }
        res = await api.login(data)
      }
      console.log(res)
      if (res.success) {
        store.dispatch('setToken', res.data.access_token)
        // // 获取并设置企业列表
        // store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_LIST)
        // 获取并设置用户信息 并看是否有默认企业
        store.dispatch('user/' + USERS.SET_USER_INFO, {
          callback: (orgId) => {
            const redirect = route.query.redirect
            if (redirect) {
              if (redirect.charAt(0) === '/') router.replace(redirect)
              else {
                window.location.href = redirect
              }
            } else {
              router.replace({
                name: client.PROJECT_LIST,
                params: {
                  orgId
                }
              })
            }
          },
          noOrgIdCallback: () => { showModal(router) }
        })
      }
    }

    onMounted(() => {

    })

    return {
      showForgetModal,
      hideForgetModal,
      login,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="less" scoped>
.login-con {
  width: 100%;
  .login {
    width: 576px;
    max-width: calc(100% - 32px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: white;
    overflow: hidden;
  }
  .login-header{
    width: 100%;
    height: 62px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
    &-icon{
      margin-top:16px ;
      margin-left: 32px;
    }
  }
}

</style>
